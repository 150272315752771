import React, {FC} from 'react';

import Logo from 'assets/logos/xiangji_logo.svg';
import * as S from './Styles';

const TopNavLogo: FC = () => (
  <S.LinkContainer to="/">
    <S.Image alt="象寄翻译 Logo" src={Logo} />
  </S.LinkContainer>
);

export default TopNavLogo;
